<template>
    <b-col xl="6">
        <b-card no-body class="card-height-100">
            <b-card-header class="align-items-center d-flex">
                <b-card-title class="mb-0 flex-grow-1">Featured Companies</b-card-title>
                <div class="flex-shrink-0">
                    <b-link href="#!" class="btn btn-soft-primary btn-sm">View All Companies <i
                            class="ri-arrow-right-line align-bottom"></i></b-link>
                </div>
            </b-card-header>

            <b-card-body>
                <div class="table-responsive table-card">
                    <table class="table table-centered table-hover align-middle table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-secondary rounded">
                                                <img src="@/assets/images/companies/img-1.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Force Medicines</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Cullera, Spain
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-secondary"><i class="ri-facebook-fill"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-danger"><i class="ri-mail-line"></i></b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-primary"><i class="ri-global-line"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-info"><i class="ri-twitter-line"></i></b-link>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <b-link href="#!" class="btn btn-link btn-sm">View More <i
                                            class="ri-arrow-right-line align-bottom"></i></b-link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-warning rounded">
                                                <img src="@/assets/images/companies/img-3.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Syntyce Solutions</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Mughairah, UAE
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-secondary"><i class="ri-facebook-fill"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-danger"><i class="ri-mail-line"></i></b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-primary"><i class="ri-global-line"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-info"><i class="ri-twitter-line"></i></b-link>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <b-link href="#!" class="btn btn-link btn-sm">View More <i
                                            class="ri-arrow-right-line align-bottom"></i></b-link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-primary rounded">
                                                <img src="@/assets/images/companies/img-2.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Moetic Fashion</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Mughairah, UAE
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-secondary"><i class="ri-facebook-fill"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-danger"><i class="ri-mail-line"></i></b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-primary"><i class="ri-global-line"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-info"><i class="ri-twitter-line"></i></b-link>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <b-link href="#!" class="btn btn-link btn-sm">View More <i
                                            class="ri-arrow-right-line align-bottom"></i></b-link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-danger rounded">
                                                <img src="@/assets/images/companies/img-4.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Meta4Systems</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Germany
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-secondary"><i class="ri-facebook-fill"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-danger"><i class="ri-mail-line"></i></b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-primary"><i class="ri-global-line"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-info"><i class="ri-twitter-line"></i></b-link>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <b-link href="#!" class="btn btn-link btn-sm">View More <i
                                            class="ri-arrow-right-line align-bottom"></i></b-link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-xs me-2 flex-shrink-0">
                                            <div class="avatar-title bg-soft-danger rounded">
                                                <img src="@/assets/images/companies/img-5.png" alt="" height="16">
                                            </div>
                                        </div>
                                        <h6 class="mb-0">Themesbrand</h6>
                                    </div>
                                </td>
                                <td>
                                    <i class="ri-map-pin-2-line text-primary me-1 align-bottom"></i> Limestone, US
                                </td>
                                <td>
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-secondary"><i class="ri-facebook-fill"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-danger"><i class="ri-mail-line"></i></b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-primary"><i class="ri-global-line"></i>
                                            </b-link>
                                        </li>
                                        <li class="list-inline-item">
                                            <b-link href="#!" class="link-info"><i class="ri-twitter-line"></i></b-link>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <b-link href="#!" class="btn btn-link btn-sm">View More <i
                                            class="ri-arrow-right-line align-bottom"></i></b-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="align-items-center mt-4 pt-2 justify-content-between d-flex">
                    <div class="flex-shrink-0">
                        <div class="text-muted">
                            Showing <span class="fw-semibold">5</span> of <span class="fw-semibold">25</span> Results
                        </div>
                    </div>
                    <ul class="pagination pagination-separated pagination-sm mb-0">
                        <li class="page-item disabled">
                            <b-link href="#" class="page-link">←</b-link>
                        </li>
                        <li class="page-item">
                            <b-link href="#" class="page-link">1</b-link>
                        </li>
                        <li class="page-item active">
                            <b-link href="#" class="page-link">2</b-link>
                        </li>
                        <li class="page-item">
                            <b-link href="#" class="page-link">3</b-link>
                        </li>
                        <li class="page-item">
                            <b-link href="#" class="page-link">→</b-link>
                        </li>
                    </ul>
                </div>
            </b-card-body>
        </b-card>
    </b-col>
</template>
